<template>
  <div>
    <!-- todo: add logo within animation ? -->
    <div
      v-for="i in 14"
      :key="'index' + i"
      class="page-transition lg:fixed h-full w-[7.2%] bg-black top-0 opacity-1"
      :style="{ left: (i - 1) * 7.15 + '%', 'animation-delay': i * 30 + 'ms' }"
    ></div>
    <div
      v-for="i in 7"
      :key="'indexj' + i"
      class="page-transition fixed lg:hidden h-full w-[14.45%] bg-black top-0 opacity-1"
      :style="{ left: (i - 1) * 14.25 + '%', 'animation-delay': i * 30 + 'ms' }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import getVideoFromRef from '~/queries/getVideoFromRef';
import { Video, VideoRef } from '~/types';

const { video } = defineProps<{
  id?: string;
  video: VideoRef;
  controls: boolean;
}>();

const sanity = useSanity();
const videoValues = ref<Video | null>(null);

onMounted(async () => {
  if (process.server) {
    return;
  }

  if (video) {
    const query = getVideoFromRef(video.refVideo._ref);
    videoValues.value = await sanity.fetch(query);
  }
});
</script>

<template>
  <video
    v-if="videoValues?.video.url"
    playsinline
    autoplay
    :id="id"
    :loop="!controls"
    :muted="!controls"
    :controls="controls"
  >
    <source
      :src="videoValues.video.url"
      :type="videoValues.video.mimeType || 'video/mp4'"
    />
  </video>
  <div
    v-else
    class="image"
  />
</template>

<style lang="postcss">
video {
  /* Avoid transition flickering on Webkit */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  object-fit: cover;
  width: 100%;
}
.image {
  width: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  padding: 56.25%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>

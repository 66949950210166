<template>
  <NuxtLink
    class="event-link grid-main py-4 lg:py-8 border-b no-underline event-line relative"
    :to="getEventSlug(route.currentRoute.value.path, item)"
    @mousemove="moveVenueImage"
  >
    <!-- Date -->
    <div
      v-if="item.cleanDate"
      class="col-span-12 lg:col-span-3 flex items-center"
    >
      <span class="text-[28px] leading-[34px] mb-1 lg:mb-0">
        {{ convertDateToEuropeanFormat(item.cleanDate.startDate) }},
        {{ item.cleanDate.startTime }}
      </span>
    </div>

    <!-- Event text-->
    <div
      class="col-span-12 lg:col-span-7 xl:col-span-5 flex flex-col justify-center relative z-[2]"
    >
      <span class="eyebrow mb-1">{{ getEventCategory(item.listFacetIdAttribute) }}</span>
      <h5 class="font-bold text-[18px] leading-[24px] mb-1">{{ item.title }}</h5>
      <p class="text-[18px] leading-[24px]">{{ item.summary }}</p>
    </div>

    <!-- Arrow Link -->
    <div
      class="col-span-12 lg:col-span-2 xl:col-span-4 mt-4 lg:mt-0 flex items-center justify-start"
    >
      <div
        class="lg:ml-auto group aspect-square cursor-pointer border-[#93A1AF] border-[0.2px] rounded-full lg:max-w-[56px] lg:max-h-[56px] flex items-center justify-center px-3 lg:px-3 lg:py-1 arrow-event"
      >
        <SVGLoader
          name="arrow-scroll-top"
          class="rotate-45"
        />
      </div>
    </div>

    <!-- Follow image -->
    <img
      :alt="'Event in Waldhaus Sils'"
      :src="item.image[0].publicUrlForCrop"
      ref="followImage"
      class="follow-img hidden lg:flex"
    />
    <!-- @mouseenter="venueHover"
      @mouseleave="venueHover" -->
  </NuxtLink>
</template>

<script setup lang="ts">
import { useEventsStore } from '~/stores/useEventsStore';
import { storeToRefs } from 'pinia';
import { Item } from '~/types';

// Props
const { item } = defineProps<{ item: Item }>();

// Store
const eventsStore = useEventsStore();
await eventsStore.fetchEventsFromApi();
// const resizeLenis = nuxtApp.$resizeLenis;
// resizeLenis();
const { categoryFilters } = storeToRefs(eventsStore);

// Composables
const { locale } = useI18n();
const route = useRouter();
const { convertDateToEuropeanFormat } = useSiteSettings();
console.log('locale.value', locale.value);
// Utils
const apiUrl =
  locale.value === 'de'
    ? 'https://developers.estm.ch/hotel-waldhaus-sils/events/'
    : 'https://developers.estm.ch/en/hotel-waldhaus-sils/events/';

// Methods
const getEventCategory = (listFacetIdAttribute: string[]) => {
  const category = categoryFilters.value.find((item) => listFacetIdAttribute.includes(item.value));
  return category?.label;
};

const getEventSlug = (eventPageSlug: string, item: Item) => {
  if (!item) return '';
  const pageSlug = item.slug.replace(apiUrl, '') || '';

  return `${eventPageSlug}/${pageSlug}?startDate=${item.cleanDate?.startDate}&startTime=${item.cleanDate?.startTime}`;
};

// Test
const followImage = ref(null);

const moveVenueImage = (e: any) => {
  if (followImage.value) {
    const linkRect = e.currentTarget.getBoundingClientRect();
    // Get the center of the NuxtLink
    const centerX = linkRect.left + linkRect.width * -1;
    const centerY = linkRect.top + linkRect.height / 2;

    // Calculate the distance from the center
    const deltaX = (e.clientX - centerX) / 4;
    const deltaY = e.clientY - centerY;

    // Apply a fraction of this distance to the image's translation
    // The smaller the fraction, the subtler the effect
    const ratio = 0.1; // Adjust this ratio to control the effect's intensity
    const translateX = deltaX * ratio;
    const translateY = deltaY * ratio;

    followImage.value.style.transform = `translate(${translateX}px, ${translateY}px)`;
  }
};
</script>

<style scoped>
.event-link:hover .follow-img {
  opacity: 1;
  -webkit-animation: fadeIn 1s forwards;
  animation: fadeIn 1s forwards;
}

.follow-img {
  opacity: 0;
  position: absolute;
  transform: translate(0%, 0%);
  transform-origin: 50% 50%;
  transition: transform 0.1s ease-out;
  pointer-events: none;
  z-index: 1;
  max-width: 400px;
  right: 10%;
  height: 110%;
  object-fit: cover;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
